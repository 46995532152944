import React, { useEffect, useMemo, useState } from 'react'
import './App.css'
import axios from 'axios'
import FleeMarketCard from './components/FleeMarketCard'
import Loader from './components/Loader'
import { createTheme, ThemeProvider } from '@mui/material'
import moment from 'moment'

export interface FleeMarket {
  url: string
  imageUrl: string
  title: string
  location: string
  exhibitorsNumber: string
  hours: string
  startDate: string
  endDate: string
  exhibitorNumbers: string
  sitePrice: string
  where: string
  phoneNumber: string
  public: string
  registerBy: string
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

const App = () => {
  const [fleeMarkets, setFleeMarkets] = useState<FleeMarket[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const response = await axios.get('https://baatezus.com/api')
    setFleeMarkets(
      response.data.sort((a: FleeMarket, b: FleeMarket) => a.startDate.localeCompare(b.startDate)),
    )
    setLoading(false)
  }

  useEffect(function () {
    fetchData()
  }, [])

  const content = useMemo(() => {
    return loading ? <Loader /> : fleeMarkets.map(f => <FleeMarketCard fleeMarket={f} />)
  }, [fleeMarkets, loading])

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">{content}</div>
    </ThemeProvider>
  )
}

export default App
