import { FC } from 'react'
import { FleeMarket } from '../App'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Link,
  Typography,
} from '@mui/material'
import moment from 'moment'

interface FleeMarketCardProps {
  fleeMarket: FleeMarket
}

const FleeMarketCard: FC<FleeMarketCardProps> = ({ fleeMarket }) => {
  const mapAddress = `https://maps.google.com/?q=1200${encodeURI(fleeMarket.where)}`

  return (
    <Card sx={{ maxWidth: 1000, margin: '30px 10px' }}>
      {fleeMarket.imageUrl && (
        <CardMedia sx={{ height: 240 }} image={fleeMarket.imageUrl} title={fleeMarket.title} />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {fleeMarket.title}
        </Typography>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Typography gutterBottom variant="h6" component="div">
            Le {moment(fleeMarket.startDate).format('DD/MM')}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {fleeMarket.exhibitorNumbers} exposants
          </Typography>
        </Box>

        <Divider />

        <Box sx={{ marginTop: '10px' }}>
          <Typography variant="body2" color="text.secondary">
            A partir de {moment(fleeMarket.startDate).format('hh:mm')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {fleeMarket.where}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Link href={mapAddress}>Voir la carte</Link>
      </CardActions>
    </Card>
  )
}

export default FleeMarketCard
